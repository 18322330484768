.navbar-toggler {
	border: 0;
}
.nav-item {
	display: flex;
	align-items: center;
}

.nav-link {
	margin: 10px 0 10px 15px !important;
	letter-spacing: 1px;
	text-align: center;
	vertical-align: middle;
}
.nav-link.active {
	color: $orange !important;
}
.nav-item--login {
	@media (min-width: 575px) {
		position: absolute;
		width: 150px;
		font-size: .8em;
		background: $blue;
		top: 0;
		right: 65px;
		a {
			display: inline-block;
			color: $white !important;
			padding: 0;
		}
	}
}
#mainNav, #communityNav {
		padding: 15px 30px;
    background-color: #fff;
		-webkit-font-smoothing: auto !important;
		-moz-osx-font-smoothing: auto !important;
    @include transition-all;
    .navbar__logo img {
        width: 150px;
				padding: 15px 0;
				@media (min-width: 768px) {
					width: 200px;
					height: 90px;
				}
    }
		@media (max-width: 575px) {
				text-align: center;
				.nav-link {
					font-size: 1.25em !important;
				}
		}
		@media (min-width: 768px) {
			padding: 15px 60px;
		}
}
#communityNav {
	padding: 0;
	flex-direction: column;
	background: #fff;
  z-index: 9999999999;
	justify-content: space-between;
	@media (min-width: 992px) {
		flex-direction: row;
	}

	.container-fluid {
		justify-content: space-around !important;
		flex-direction: row;
		margin: 0;
		padding: 0;
		background: $white;
		height: 100%;
		@media (min-width: 768px) {
			height: 120px;
			width: 100%;
			padding: 0;
			flex-direction: row;
		}

		@media (min-width: 992px) {
			width: 40%;
		}
	}
	.angle-right {
	  @include angle-right($blue);
		border-width: 120px 30px 0 0;
		@media (min-width: 768px) {
				display: inline-block;
		}
	}
	.navbar__logo {
		display: block;
		padding: 0 .5em;
		@media (min-width: 768px) {
			display: inline-block;
			padding: 0 1em;
			img {
				width: 130px;
			}
		}
		@media (min-width: 1200px) {
			img {
				width: 185px;
			}
		}
	}
}
//this element appears on the community landing pages
.nav__resident-login {
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 10px;
	background: $blue;
	color: $white;
	font-size: .7em;
	letter-spacing: 1px;
	text-transform: uppercase;
	span {display: none}
	@media (min-width: 768px ) {
		position: static;
		width: 200px;
		background: $white;
		color: $blue;
		span {display: inline-block;}
	}
	@media (min-width: 1200px ) {
		font-size: .8em;
		letter-spacing: 1px;
	}
}
.nav__community-name {
	width: 100%;
	@include font-smoothing;
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 0 30px;
	margin: 0;
	background: $blue;
	color: $white;
	font-size: 1.5em;
	span {
		align-items: center;
	}
	// @media (min-width: 768px) {
	// 	// width: auto;
	// }
	@media (min-width: 1200px ) {
		padding: 0 60px;
	}
}
.community__subnav {
  margin: 0;
  padding: 0;
  font-size: 0;
}
.subnav__menu {
  margin: 0 auto;
  padding: 0;
	display: flex;
	flex-direction: row;
}
.subnav__menu-item {
  display: inline-block;
  margin: 0;
  padding: 0 ;
  font-size: .8rem;
	font-weight: 500;
	text-align: center;

	@media (min-width: 768px) {
  	font-size: 1rem;
		font-weight: 400;
	}

	.subnav__menu-link {
	  display: inline-block;
		padding: 1em;
		color: $gray;
		transition: all .35s ease;
		
		&.pref-employer-link {
			opacity: 0;
			pointer-events: none;
		}

		@media (min-width: 768px) {
	  	padding: 1em 2em 1em 0;
		}
	}
}
