.btn-angled {
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(-30deg);
    -ms-transform: skew(-30deg);
    transform: skew(-30deg);

    div {

        -webkit-transform: skew(30deg);
        -ms-transform: skew(30deg);
        transform: skew(30deg);
        font-weight: 500;
    }
    &.btn-sq-right,
    &.btn-sq-left {
    	&:hover {
    		//background-color: lighten($orange, 10%);
    		background-color: $orange;

    	}
        div {
            -webkit-transform-origin: 100% 0;
            -ms-transform-origin: 100% 0;
            transform-origin: 100% 0;
            -webkit-transform: skew(30deg);
            -ms-transform: skew(30deg);
            transform: skew(30deg);
        }
    }

    &.btn-sq-right:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 50%;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        background-color: $orange;
        -webkit-transform-origin: 100% 0;
        -ms-transform-origin: 100% 0;
        transform-origin: 100% 0;
        -webkit-transform: skew(30deg);
        -ms-transform: skew(30deg);
        transform: skew(30deg);
        z-index: -1;
    }
    &.btn-sq-right:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 50%;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        background-color: $orange;
        -webkit-transform-origin: 100% 0;
        -ms-transform-origin: 100% 0;
        transform-origin: 100% 0;
        -webkit-transform: skew(30deg);
        -ms-transform: skew(30deg);
        transform: skew(30deg);
        z-index: -1;
    }
}