.section__search-tools {
    padding-top: 2em;
    padding-bottom: 2em;
    select {
      width: 95%;
    }
}

@media (min-width: 992px) {
  .community__map {
    -ms-flex-order: 1;
    order: 1;
  }
  .community__listings {
    -ms-flex-order: 2;
    order: 2;
  }
}
.form__label {
  display: block;
  margin-bottom: 5px;
  color: $gray;
  letter-spacing: 1px;
}
header.search {
    position: relative;
    width: 100%;
    min-height: auto;
    height: 100%;
    margin-bottom: 1rem;
}
.form-control {
    padding: .5rem;
    font-family: $body-font;
    margin: 10px 0 15px;
    border-radius: 0;
    -webkit-appearance: none;
    text-transform: none;
}
.form-group {
    position: relative;
    &:before {
        position: absolute;
        top: 1%;
        right: 5px;
        content: "";
        display: block;
        background-color: #5f913f;
        z-index: 2;
        width: 42px;
        height: 36px;
    }
    &:after {
        position: absolute;
        top: 15%;
        right: 23px;
        content: "\f002";
        font-family: FontAwesome;
        display: block;
        z-index: 3;
        width: 10px;
        height: 10px;
        color: $white;
    }
}
select.form-control {
    margin: 0.5rem auto;
    border-radius: 0;
    appearance: none;
    text-transform: none;
    background-image: url(/assets/img/icon_dropdown.svg);
    background-position: right;
    background-repeat: no-repeat;
}
select {
    text-transform: uppercase;
}
// trouble positioning background image in IE10+11, so hiding with this media query
//https://philipnewcomer.net/2014/04/target-internet-explorer-10-11-css/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
 select.form-control {
   background-image: none;
 }
}
