// Global styling for this template

a,
button {
  outline: none !important;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  background-color: #fbfbfb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @include body-font();
  -webkit-tap-highlight-color: $gray-darker;
}

a {
  color: $theme-primary;
  @include transition-all;

  &:focus,
  &:hover {
    color: darken($theme-primary, .1);
  }
}

.bg-primary {
  background-color: $theme-primary !important;
}

.bg-gray {
  background-color: $medium-gray;
}

.bg-dark {
  color: white;
  background-color: $gray-darker !important;
}

.text-faded {
  color: fade-out(white, .3);
}

::-moz-selection,
::selection {
  color: white;
  background: $gray-darker;
  text-shadow: none;
}

img::-moz-selection,
img::selection {
  color: white;
  background: transparent;
}

.disclaimer-community {
  color: $gray;
  padding: 2em;
  font-size: 0.9em;
}

.hidden {
  display: none;
}