.modal {
  .modal-body {
    padding: 2em;
  }

  li {
    padding-bottom: .5em;
  }

  .modal__floorplan-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 90%;
    height: 75vmin;
  }

  .modal__floorplan-info {
    padding-left: 7.5%;
  }

  .modal-content,
  .modal-dialog {
    max-width: 1200px;
  }

  .floorplan__name {
    font-size: 2em;
    color: $blue;
    display: inline-block;
    margin: .5em 1em .5em 0;
    padding: 0;
  }

  .floorplan__sqfootage,
  .floorplan__unitmix {
    font-size: 1.5em;
    display: inline-block;
    margin-right: 1em;
  }
.floorplan__features-headline {
  margin-bottom: 1em;
  color: $blue;
}
  .floorplan__features {
    padding: 3em 0;
    color: $gray;
    font-size: .8em;
    line-height: 1.25;
    letter-spacing: .025em;
    text-transform: uppercase;
  }

  .floorplan__feature {
    margin-bottom: .5em;
  }

  .floorplan__apply-btn {
    display: block;
    border: 0;
    background-color: $green;
    color: $white;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 1em 3.5em;
    margin: 1em 0;
    max-width: 300px;
  }

  .floorplan__apply-btn:before {

  }

  .siblings {
    display: inline-block;
  }
}
