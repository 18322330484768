// Skew rhombus shape parent and unskew child
@mixin skew {transform: skew(-20deg);}
@mixin unskew {transform: skew(20deg); display: inline-block;}

.section__callout {
    position: relative;
    padding: 2em 0;
    margin-bottom: 60px;
    color: $white;
    background-color: $medium-gray;
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
    }
}
.callout__headline {
    color: $white;
    margin: .25em 0 1em 0;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
}
.callout__cta {
    display: inline-block;
    padding: 1em 4.5em 1em 3.5em;
    color: $white;
    background: $orange;
    white-space: nowrap;
    font-weight: 600;
    @include skew
    span {
      @include unskew;
    }
    &:hover, &:hover:before {
        color: $white;
        background: $theme-primary;
    }
    &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 40px;
        top: 0;
        left: -20px;
        background-color: $orange;
        transform-origin: 100% 0;
        transform: skew(20deg);
        z-index: -1;
        transition: background 0.2s;
    }
}
.tm {
    font-weight: 200;
    font-size: 14px;
    vertical-align: super;
}
.section__intro {
  margin-bottom: 3em;
}
.section__featured-communities {
    margin-bottom: 60px;
}
