.carousel-item {
  height: 80vw;
  max-height: 80vh;
}
.masthead__headline {
    color: $white;
    position: relative;
    font-size: 2em;
    padding: 0;
    margin: 0 0 .5em 0;
    font-size: 1.5em;
    text-align: center;
}
.carousel-control-prev, .carousel-control-next {
  opacity: .85;
  text-align: center;
}
.carousel-icon__prev, .carousel-icon__next {
  position: relative;
  top: 25px;
  width: 100px;
  height: 100px;
  svg { opacity: 1 !important; }
}
#carousel-arrow-prev, #carousel-arrow-next {
  position: absolute;
  width: 100%;
}
#carousel-arrow-next {
  right: 0;
}
.masthead__search-tool {
  z-index: 2;
  display: block;
  padding: 30px;
  background: rgba(9, 44, 116, 0.9);
  @media (min-width: 640px) {
    position: absolute;
    top:123px;
    width: auto;
    overflow: hidden;
    left: -50px;
    padding: 30px 120px;
    @include skew;
    animation: fadeInLeft .75s ease-out;
    -moz-animation: fadeInLeft .75s ease-out; /* Firefox */
    -webkit-animation: fadeInLeft .75s ease-out; /* Safari and Chrome */
    -o-animation: fadeInLeft .75s ease-out; /* Opera */
    .masthead__headline, .masthead__cta {
      @include unskew;
      display: block;
    }
    .masthead__headline {
      left: -20px;
      font-size: 1.5em;
      text-align: left;
    }
  }
}
.masthead__cta {
    display: block;
    padding: 1em;
    text-align: center;
    background: $green;
    color: $white;
    &:hover, &:hover:after {
      background: $orange;
      color: $white;
    }
    &:after {
        @include angled($green);
        display: none;
        @media (min-width: 640px) {
          display: block;
        }
    }
}

#pref-employer-program {
  .masthead__cta {
    &::after {
      display: none;
    }
  }
}

.carousel-item {
  @include background-cover;
  color: white;
  background-position: center left;
}
.carousel-caption {
  bottom: 60px;
  right: 4.5%;
  left: inherit;
  font-weight: bold;
}
.carousel-indicators {
  bottom: 30px;
  right: -10%;
  left: inherit;
  li {
    margin: 0;
    height: 2em;
    width: 1.5em;
    transform: skew(-20deg);
  }
}
