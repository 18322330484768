.community__name-header {
  margin: 0;
  padding: .75em 0;
  line-height: 1;
  background: $theme-primary;
  color: $white;

  .container {
    margin: 0 auto;
    padding: 0;
  }
}

#isSpecial {
  display: none;
  // transform: scale(0);
  // transition: transform .25s ease;
  // transform-origin: left;
}

.community__login-link {
  color: $white;
}

.community__hero {
  position: relative;
  height: 75vw;
  max-height: 75vh;
  background-size: cover;
  background-position: center;
}

.header__chat-button {
  position: absolute;
  left: -1em;
  top: 3em;
  padding: 1em 4em;
  background: $green;
  color: $white;
  @include skew;

  span {
    @include unskew
  }

  &:hover {
    color: $white;
    background: $blue;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.community__special-offer {
  position: relative;
  text-align: left;
  background: $orange;
  color: $white;
  padding: 1.25em 2em;
  line-height: 1.25;
  letter-spacing: .05em;

  span {
    font-weight: 700;
  }

  @media (min-width: 769px) {
    padding-left: 60px;
  }

  // p {
  //   animation: 1.5s ease-in-out 0s 1 slideInFromLeft;
  //   -webkit-animation: 1.5s ease-in-out 0s 1 slideInFromLeft;
  // }
}

.community__description p {
  @extend .intro__paragraph;
}

.community__headline {
  display: inline-block;
  position: relative;
  height: 70px;
  margin: 30px 0;
  padding: .5em 0;
  background: $orange;
  color: $white;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
  }

  &:before {
    width: 1000%;
    background: #e28330;
    left: -1000%;
  }

  &:after {
    // width: 0;
    // padding-left: 50px;
    // border-style: solid;
    // border-width: 70px 30px 0 0px;
    // border-color: $orange transparent transparent
    width: 100px;
    background-color: #e28330;
    transform: skew(-25deg);
    z-index: -1;
    margin-left: -35px;
  }
}

.community__subhead {
  color: $orange;
  margin-bottom: 1em;
}

.community__features-list {
  margin-bottom: 3em;
}

.features__list-item {
  list-style-type: disc;
  margin: 0 0 1em 1em;
  color: $gray;
  font-size: .9em;
  letter-spacing: .025em;
  line-height: 1.5;
}

.section__floorplans {
  margin-bottom: 60px;

  .nav-item {
    background: $blue;
    padding: 0;
  }

  .nav-link {
    @include font-smoothing;
    color: $white;
    padding: 1.25em 2em;
    margin: 0 !important;
    border: 0;
    border-radius: 0;
    font-weight: bold;
    font-size: .75em;

    @media (min-width: 769px) {
      font-size: 1em;
    }
  }

  .nav-link.active {
    background: $medium-gray;
    color: $white !important;
  }
}

.community__floorplan-cards {
  padding: 60px 0;
  background: $medium-gray;

  .tab-content {}

  .tab-pane {}
}

.floorplan__card {
  display: inline-flex;
  position: relative;
  align-items: center;
  padding: 1em;
  margin: .5em;
  width: 95%;
  max-width: 440px;
  background: $white;
  color: $blue;
}

.floorplan__thumbnail {
  height: 125px;
  width: 125px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.floorplan__info {
  text-transform: uppercase;
  font-size: .85em;
  margin-left: 2em;
}

.floorplan__name {
  text-transform: none;
  font-size: 1.25rem;
}

.floorplan__sqfootage,
.floorplan__unitmix {
  color: $gray;
  margin-bottom: 10px;
  letter-spacing: .05em;
}

.floorplan__link {
  color: $orange;
}

.floorplan__expand-btn {
  display: none;
  position: relative;
  line-height: 0;
  flex: 1;

  @media (min-width: 460px) {
    display: block;
  }
}

.floorplan__expand-icon {
  position: absolute;
  right: 2em;
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  transform: translateY(-.75em); // this centers the icon vertically in firefox where flex 1 was buggy
}

.contact__address,
.contact__phone,
.contact__hours,
.contact__subhead {
  display: block;
  font-size: 1.25em;
  line-height: 1.25;
  color: $gray;
  margin-bottom: 1em;
}

.contact__subhead {
  display: block;
  margin-bottom: .5em;
  color: $orange;
}

.contact__hours {
  margin-bottom: 2em;
  max-width: 300px;

  li {
    margin-bottom: 5px;

    span {
      float: right;
      //margin-left: 5%;
    }
  }
}

/* covers annoying chat widget */
#footer,
#carouselIndicators,
.community__special-offer,
.section__callout {
  z-index: 9999999999;
}

.section__callout--community {
  background: $gray-lighter;
  margin-top: 0;

  .callout__headline {
    color: $orange;
  }
}

.is-pref-employer {
  .community__headline {
    @media (max-width: 375px) {
      font-size: 1.8rem;
    }
  }

  .orange-text {
    color: #e28330;
  }

  .employer-program-item {
    text-transform: uppercase;
    color: #e28330;
    line-height: 1.5;
    padding-bottom: 0;

    a {
      color: #e28330;

      &:hover {
        font-weight: 600;
      }
    }
  }

  [data-update="pref-employer-img"] {
    height: calc(432px - 2rem);

    @media (max-width: 992px) {
      display: none;
    }
  }

  .participants-intro {
    margin-bottom: 1rem;
    color: #555555;
    font-size: .9em;
    letter-spacing: .025em;
    line-height: 1.75;
  }
}