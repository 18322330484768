// Mixins
// Bootstrap Button Variant
@mixin button-variant($color, $background, $border) {
    color: $color;
    border-color: $border;
    background-color: $background;
    &.focus,
    &:focus {
        color: $color;
        border-color: darken($border, 25%);
        background-color: darken($background, 10%);
    }
    &:hover {
        color: $color;
        border-color: darken($border, 12%);
        background-color: darken($background, 10%);
    }
    &.active,
    &:active,
    .open>&.dropdown-toggle {
        color: $color;
        border-color: darken($border, 12%);
        background-color: darken($background, 10%);
        &.focus,
        &:focus,
        &:hover {
            color: $color;
            border-color: darken($border, 25%);
            background-color: darken($background, 17%);
        }
    }
    &.active,
    &:active,
    .open>&.dropdown-toggle {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &.focus,
        &:focus,
        &:hover {
            border-color: $border;
            background-color: $background;
        }
    }
    .badge {
        color: $background;
        background-color: $color;
    }
}

@mixin transition-all() {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
}

@mixin background-cover() {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

$header-font: 'Maven Pro';
$body-font: 'Libre Franklin';
$serif-font: serif;

@mixin serif-font() {
    font-family: $serif-font, serif;
}

@mixin body-font() {
    font-family: $body-font, sans-serif;
}

@mixin header-font() {
    font-family: $header-font, sans-serif;
}

//BH additions
@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

$image-path: '../img' !default;
$fallback-extension: 'png' !default;
$retina-suffix: '@2x';
@mixin background-image($name, $size:false) {
    background-image: url(#{$image-path}/#{$name}.svg);
    @if($size) {
        background-size: $size;
    }
    .no-svg & {
        background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});

        @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
            background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension});
        }
    }
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}