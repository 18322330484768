#map,
#communities-map,
#contact-map  {
    margin-bottom: 1em;
    min-height: 50vh;
}
.skewed-edge:before {
    content: '';
    position: absolute;
    left: -4px;
    top: -1px;
    width: 25px;
    height: 46px;
    background-color: $white;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(30deg);
    -ms-transform: skew(30deg);
    transform: skew(-30deg);
}
.circular-plus::after {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $green;
    position: relative;
    display: block;
    top: 78px;
    right: 42px;
    cursor: pointer;
}
.circular-plus::before {
    content: '+';
    position: relative;
    top: 102px;
    left: -37.1px;
    font-size: 18px;
    color: $green;
    cursor: pointer;
}
.diff-communities {
    margin-top: 15px;
    overflow: scroll;
    //white-space: nowrap;
}
.community-img {
    background-size: cover;
    background-repeat: no-repeat;
    width: 49%;
    height: 100%;
    display: inline-block;
}

.community-wrap {
    margin: 0 2rem 1rem 0;
    display: inline-block;
    position: relative;
    width: 100%;
    height: 88%;
    background-color: white;
    border: 1px solid $gray;
}

.community-text {
    display: inline-block;
    margin: 0;
    padding: 2rem 1rem;
    height: 100%;
    vertical-align: top;
    width: 50%;
}

.top-bottom-space {
    padding-bottom: 2rem;
}


@media (min-width: 768px) {
    .diff-communities {
        max-height: 100vh;
        background-color: $white;
        overflow: scroll;

    }
    .community-wrap {
        margin-bottom: 1rem;
        display: block;
        position: relative;
        width: 100%;
        height: 30%;
        background-color: white;
        border: 1px solid $gray;
    }
}
.gm-style-iw {
  margin: 0 !important;
  margin-right: -30px !important;
  padding: 0 !important;
  top: 0 !important;
  left: 0 !important;

  overflow: visible !important;


  #content {
    padding: 1.2em 0 0 !important;
    margin-left: 220px;
    height: 130px;
    font-size: 1.25em;
    line-height: 1.5;
  }
}
.tooltip__thumb {
  position: absolute;
  width: 200px;
  margin: 0;
  height: 150px;
  border-radius: 2px 0px 0px 2px;
}
