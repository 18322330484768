// Functions
// ---------
// General functions for projects
// ==========

// Color
// -----
// An easy way to get colors from the $colors map
// =====

@function color($color, $map: $colors) {
  @return map-get($map, $color);
}

// Map-fetch
// ---------
// An easy way to fetch a deep value in a multi-level map.
// Works much like map-get() except that you pass multiple
// keys as the second parameter to go down multiple levels
// in the nested map.
//
// https://gist.github.com/jlong/8760275
// =========

@function map-fetch($map, $keys) {
  $key: nth($keys, 1);
  $length: length($keys);
  $value: map-get($map, $key);
  @if $length > 1 {
    $rest: ();
    @for $i from 2 through $length {
      $rest: append($rest, nth($keys, $i));
    }
    @return map-fetch($value, $rest);
  } @else {
    @return $value;
  }
}

// Strips unit
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}


// Fade in Animation

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0) skew(-20deg);
    transform: translate3d(-20%, 0, 0) skew(-20deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) skew(-20deg);
    transform: translate3d(0, 0, 0) skew(-20deg);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0) skew(-20deg);
    transform: translate3d(-20%, 0, 0) skew(-20deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) skew(-20deg);
    transform: translate3d(0, 0, 0) skew(-20deg);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
