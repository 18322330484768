@media (min-width: 991px) {
  .section__contact {
      margin: 60px auto;
  }
  .contact__text {
    order: 1;
  }
  .contact__image {
    order: 2;
  }
}
.contact__image {
  margin-bottom: 1em;
  .img-fluid {
    border-radius: 3px;
  }
}
.contact__form {
  margin-top: 1.5em;
}

.contact-us .row {
    margin-right: 0px;
}

.form-resize {
    margin: 0;
    padding-bottom: 1rem;
    font-size: 14px;
    text-transform: uppercase;
    label {
        padding-bottom: .5rem;
        font-size: 14px;
        text-transform: uppercase;
    }
}

.form-grey {
    background-color: $gray-lighter;
    border: 1px solid $gray-lighter;
}

.caret:after {
    position: relative;
    content: "";
    display: inline-block;
    z-index: 3;
    width: 10px;
    height: 10px;
    transform: translateY(-70%) rotate(45deg);
    border-bottom-right-radius: 2px;
    border-right: 2px solid #5f913f;
    border-bottom: 2px solid #5f913f;
    float: right;
    margin-right: 20px;
    margin-top: -18px;
}

.contact-form-btn {
    min-width: 150px;
    margin: 1em 0 5em 0;
    background: $green;
    color: $white;
    font-size: 1rem;
    padding: 1em 1.5em;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
}
.location-info {
    margin-bottom: 2em;
   a {
      color: #23356f;
      font-weight: normal;
    }
    h3 {font-size: 1.5em}
}
.spcl-select {
    margin-left: 0.5rem;
    padding-top: 0.5rem;
    background-color: $gray-lighter;
    width: 100%;
    height: 35px;

}
