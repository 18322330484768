.featured-community__card {
	-webkit-font-smoothing: auto !important;
	-moz-osx-font-smoothing: auto !important;
	&:hover .featured-community__info {
		background-color: $theme-primary;
	}
	&:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    border-width: 100px 55px 0 0;
    border-color: #fbfbfb transparent transparent transparent;
    border-style: solid;
	}
}
.featured-community__thumbnail {
	height: 250px;
	border-radius: 3px 3px 0 0;
	background-size: cover;
	background-position: center;
}
.hasbadge {
	background-position: left !important;
}
.featured-community__info {
  @include font-smoothing;
	height: 150px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1em;
	text-align: center;
	background-color: $bluegray;
	transition: background .3s;
	border-radius: 0 0 3px 3px;
	margin-bottom: 2em;
}
.featured-community__name {
	color: $white;
	font-size: 1.2em;
	text-transform: uppercase;
	margin-bottom: .5em;
}
.featured-community__place,
.featured-community__rent {
	color: $white;
	font-size: 1em;
}
.featured-community__place {
	margin-bottom: 1em;
}
.featured-community__rent {
	text-transform: uppercase;
	margin-bottom: .25em;
	strong {
		font-weight: bold;
	}
}

/* Communities Page Cards */

.community__card {
	min-height: 150px;
	border: 1px solid $gray-light;
	margin-bottom: 10px;
	position: relative;
	&:after {

	}
}
.community__thumbnail {
	position: absolute;
	left: -2px;
	top: -2px;
	height: calc(101%;
	background-color: $gray-light;
	background-size: cover;
	/* need to align left with downtown collection badge */
	background-position: center; 

	width: 35%;
	float:left;
	border: 1px solid #fff;
	border-right: none;
	border-bottom: none;
	box-sizing: content-box;
	&:after {
		content: ' ';
		position: absolute;
		top: 0;
		left: 0;
		border-width: 40px 25px 0 0;
		border-color: #fbfbfb transparent transparent transparent;
		border-style: solid;
	}
}
.community__info {
	margin-left: 37.5%;
	padding: 30px 15px;
}
.community__name {
	font-size: 1em !important;
	text-transform: uppercase ;
	font-weight: 500;
	color: $theme-primary;
	font-family: $header-font;
}
.community__place, .community__rent {
	font-size: .9em;
	color: $gray;
	margin-bottom: 10px;
}
.community__rent {}
.community__phone {
	font-size: .9em;
	a {
		text-decoration: none;
		color: $orange;
	}
}
.community-card__apply-link {
    position: absolute;
    right: 0;
    bottom: 2.5em;
    background: $orange;
    color: $white;
    padding: 10px;
    font-size: .85em;
    letter-spacing: 0;
		display: none;
		&:hover {
			color: $white;
			background: $blue;
		}
}
.community__card:hover .community-card__apply-link {
	display: block;
}
