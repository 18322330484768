// Variables

// Gray and Brand Colors for use across theme

$theme-primary: #092c74;
$theme-primary-opacity: rgba(9,44,116,0.9);

$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default;
$gray-dark: lighten($gray-base, 20%) !default;
$gray: lighten($gray-base, 33.5%) !default;
$gray-light: lighten($gray-base, 66%) !default;
$gray-lighter: lighten($gray-base, 93.5%) !default;

$bluegray: #949ba8;

$green: #5f913f;
$medium-gray: #bbbdbf;
$orange: #e28330;
$white: #ffffff;

//Cool CSS

//background-image: linear-gradient(-90deg,rgba(67,149,111,0.7),#43956f 95%);
