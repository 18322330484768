#footer {
  position: relative;
}
.section__footer {
  background: $theme-primary;
  padding: 3em 0 1.5em;
  color: white;
  text-align: center;
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;
}
.footer__nav {
    padding: 0;
    list-style-type: none;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 1.5em;
}
.footer__nav-item {
  display: block;
}
.footer__nav-link {
    padding: .5em;
    font-size: 13px;
    color: $medium-gray;
		line-height: 3;
}
.footer__nav-link:hover, .footer__nav-link.active {
      color: $orange !important;
}
.footer__copyright {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 100;
    color: $medium-gray;
    a {color: $medium-gray;}
    margin-top: 13px;
}
.pipe-orange {
    color: $orange;
    margin: 0 .5em;
}
@media (min-width:552px) {
  .footer__nav-item {
    display: inline-block;
  }
}
@media(min-width: 992px) {
  .footer__nav {
    text-align: left;
  }
  .footer__nav-item {
    display: inline-block;
    padding: 0 2em 0 0;
}
  .footer__copyright {
    text-align: right;
  }
}
